
import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import { bus } from "@/main";
import { DocumentFolder, DocumentType } from "@/store/modules/document.store";
import { Action } from "vuex-class";
import { HasDocuments } from "@/mixins/has-documents";

@Component({
    components: {
        documentFolder: () => import("@/components/document/folder.vue"),
        documentFile: () => import("@/components/document/file.vue"),
        documentPlaceholder: () => import("@/components/document/placeholder.vue"),
        documentUpload: () => import("@/components/document/upload.vue"),
    },
})
export default class DocumentGrid extends Mixins(HasDocuments) {
    @Action("document/bulkDownload") bulkDownload!: (payload: any) => Promise<void>;

    @Prop() documents!: Document[];
    @Prop({ default: "" }) search!: string;
    @Prop() activity!: Activity;
    @Prop() mode!: "partner" | "admin" | "client";

    @Ref("documentFile") documentFiles!: any;

    /**
     * -----------------
     * Private variables
     * -----------------
     */

    DocumentFolder = DocumentFolder;

    selectedFiles: Document[] = [];

    /**
     * -----------------
     * File getters
     * -----------------
     */

    /**
     * Get all the placeholders for a given currentFolder
     *
     * @returns DocumentType[]
     */
    get placeholders() {
        const usedDocumentTypes = this.documents.map((d) => d.type);

        let placeholders: DocumentType[] = [];

        switch (this.currentFolder) {
            case DocumentFolder.mediationAgreement:
                placeholders = [DocumentType.bemiddelingsOvereenkomst];
                break;
            case DocumentFolder.legalProvisions:
                placeholders = [DocumentType.eigendomstitel, DocumentType.ovam, DocumentType.stedenbouw, DocumentType.rooilijnplan, DocumentType.kadastraalPlan, DocumentType.kasatraleLegger, DocumentType.kadastraleLeggerAlgemeneDelen, DocumentType.rvv, DocumentType.klim, DocumentType.watertoets, DocumentType.actaMaps, DocumentType.vgiUittreksel, DocumentType.risicokaartWaterbeheerder];
                break;
            case DocumentFolder.syndic:
                placeholders = [DocumentType.basisakte, DocumentType.jaarverslag1, DocumentType.jaarverslag2, DocumentType.jaarverslag3, DocumentType.verslagAv1, DocumentType.verslagAv2, DocumentType.verslagAv3, DocumentType.artikel57711, DocumentType.balans1, DocumentType.balans2, DocumentType.balans3, DocumentType.epcAlgDelen, DocumentType.blokpolis];
                break;
            case DocumentFolder.inspections:
                placeholders = [DocumentType.epc, DocumentType.elektrischeKeuring, DocumentType.offerteKeuringConform, DocumentType.stookolietankKeuring, DocumentType.zonnepanelenKeuring, DocumentType.asbestInventaris];
                break;
            case DocumentFolder.photos:
                placeholders = [];
                break;
            case DocumentFolder.salesAgreement:
            case DocumentFolder.openSalesAgreement:
            case DocumentFolder.closedSalesAgreement:
            case DocumentFolder.salesAgreementSub:
                placeholders = [];
                break;
            case DocumentFolder.approvedSalesAgreement:
                placeholders = [DocumentType.aankoopbelofte];
                break;
            case DocumentFolder.varia:
                placeholders = [];
                break;
            case DocumentFolder.vgeEpc:
                placeholders = [DocumentType.vgeEpcDocumentationIsolation, DocumentType.vgeEpcDocumentationTechnics, DocumentType.vgeEpcDocumentationConstruction_plans, DocumentType.vgeEpcDocumentationOther, DocumentType.vgeEpcDocumentationPhotos];
                break;
            case DocumentFolder.vgeEk:
                placeholders = [DocumentType.vgeEkDocumentationSchemes, DocumentType.vgeEkDocumentationOther, DocumentType.vgeEkInvoiceRegularisation];
                break;
            case DocumentFolder.vgeAsbestos:
                placeholders = [DocumentType.vgeAsbestosAssignmentAgreement, DocumentType.vgeAsbestosDocumentationConstructionPlans, DocumentType.vgeAsbestosDocumentationOther, DocumentType.vgeAsbestosDocumentationPostIntervention];
                break;
            case DocumentFolder.vgeImmoSigns:
                placeholders = [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra, DocumentType.vgeImmoSignDesignFiles];
                break;
            case DocumentFolder.invoice:
                placeholders = [];
                break;
            case DocumentFolder.asbestosProject:
                placeholders = [DocumentType.asbestosProjectAssignmentAgreement, DocumentType.asbestosProjectDocumentationConstructionPlans, DocumentType.asbestosProjectDocumentationOther, DocumentType.asbestosProjectDocumentationPostIntervention];
                break;
            case DocumentFolder.otherInspections:
                placeholders = [];
                break;
            default:
                placeholders = [];
                break;
        }

        return placeholders.filter((dt: DocumentType) => !usedDocumentTypes.includes(dt));
    }

    /**
     * Get the filtered result of documents based on the search criteria
     *
     * @returns Document[]
     */
    get filteredDocuments() {
        if (this.search) {
            return this.documents.filter((d) => d.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()));
        }

        return this.documents.filter((d) => d.meta?.folder === this.currentFolder);
    }

    /**
     * -----------------
     * File methods
     * -----------------
     */

    /**
     * Determine if a given value is a DocumentFolder
     *
     * @param folder string|DocumentFolder
     *
     * @returns DocumentFolder
     */
    isDocumentFolder(value: any): value is DocumentFolder {
        if (typeof value !== "string") {
            return false;
        }

        return (Object.values(DocumentFolder) as string[]).includes(value);
    }

    /**
     * Get the current folder content and download it in a zip file
     *
     * @param folder DocumentFolder
     *
     * @returns void
     */
    handleFolderContentDownload(folder: DocumentFolder) {
        if (!this.activity) {
            return;
        }

        const documents = this.documents.filter((d) => d.meta?.folder === folder);

        if (documents.length === 0) {
            return;
        }

        let name = this.$te(`views.documents.folders.${folder}`) ? this.$t(`views.documents.folders.${folder}`) : "bestanden";

        this.bulkDownload({ query: { per_page: 250, q: { and: { "meta.folder": folder, activity_id: this.activity.id } } }, name: `${this.activity.name} - ${name}.zip` });
    }

    /**
     * get the total file count of a given folder and all it's subfolders
     *
     * @param folder DocumentFolder|null
     *
     * @returns number
     */
    getFileCount(item: DocumentFolder | null) {
        if (!item) {
            return;
        }

        let documentsInFolder = this.documents.filter((d) => d.meta?.folder === item).length;

        (this.recursiveChildFolders[item] ?? []).forEach((folder: DocumentFolder) => {
            documentsInFolder += this.documents.filter((d) => d.meta?.folder === folder).length;
        });

        return documentsInFolder;
    }

    /**
     *  Add a new file to the list of selected files
     *
     * @param document Document
     *
     * @returns void
     */
    handleFileSelected(document: Document) {
        this.selectedFiles.push(document);
    }

    /**
     * Remove a file from the list of selected files
     *
     * @param document Document
     * @param index number
     *
     * @returns void
     */
    handleFileDeSelected(document: Document, index: number) {
        this.selectedFiles.splice(index, 1);
    }

    /**
     * Select all files in the curernt folder by looping over the documentFiles refs and calling the selectFile method
     *
     * @returns void
     */
    handleSelectAllFiles() {
        this.documentFiles.forEach((documentFile: any) => {
            documentFile.selectFile();
        });
    }

    /**
     * DeSelect all files in the curernt folder by looping over the documentFiles refs and calling the DeselectFile method
     *
     * @returns void
     */
    handleDeSelectAllFiles() {
        this.documentFiles.forEach((documentFile: any) => {
            documentFile.deselectFile();
        });
    }

    /**
     * Handle clearing the selected files array
     *
     * @returns void
     */
    handleClearSelectedFiles() {
        this.selectedFiles = [];
    }

    /**
     * -----------------
     * File emitters
     * -----------------
     * @open-folder: DocumentFolder
     */

    @Emit("open-folder")
    handleFolderOpen(folder: DocumentFolder) {
        return folder;
    }

    handleFilePreview(file: Document) {
        bus.$emit("show-preview-media", file.media[0]);
    }
}
